.button {
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 30px;
  font-family: var(--bold-font);
  font-weight: bold;
  font-size: 18px;
  transition: var(--short-transition);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  line-height: 1em;
  z-index: 5;
}

.button[data-variant="primary"] {
  background-color: var(--red);
  color: var(--white);
  border: 2px solid var(--red);
}

.button[data-variant="primary"]:hover {
  background-color: var(--white);
  color: var(--black);
}

.button[data-variant="secondary"] {
  background-color: var(--white);
  color: var(--black);
  border: 2px solid var(--light-grey);
}

.button[data-variant="secondary"]:hover {
  border: 2px solid var(--black);
  color: var(--white);
  background-color: var(--black);
}

.button[data-variant="secondary"][data-light="true"] {
  background-color: var(--white);
  border-color: var(--white);
}

.button[data-variant="secondary"][data-light="true"] .icon {
  color: var(--black);
}

.button[data-variant="secondary"][data-light="true"]:hover .icon {
  color: var(--red);
}

.button[data-variant="secondary"][data-light="true"] .iconContainer::before {
  border-color: var(--black);
}

.button[data-variant="secondary"][data-light="true"]:hover
  .iconContainer::before {
  border-color: var(--white);
}

.button[data-variant="secondary"] .icon {
  color: var(--light-grey);
}

.button span {
  display: block;
  font-family: var(--bold-font);
  letter-spacing: 0;
}

.iconContainer {
  position: relative;
}

.icon {
  transition: var(--short-transition);
  position: relative;
  color: var(--white);
}

.iconContainer::before {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  height: 30px;
  width: 30px;
  border: 1px solid var(--white);
  border-radius: 50%;
  transition: var(--short-transition);
}

.button:hover .icon {
  transform: translateX(4px);
  color: var(--black);
}

.button:disabled,
.button:hover:disabled {
  background-color: var(--grey);
  border-color: var(--grey);
  cursor: initial;
  color: var(--white);
}

/* @media screen and (max-width: 768px) {
	.button {
		padding: 10px 16px;
	}
} */
