#iconMenu {
	width: 60px;
	height: 45px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

#iconMenu span {
	display: block;
	position: absolute;
	height: 9px;
	width: 100%;
	background: #000;
	background-color: currentColor;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#iconMenu span:nth-child(1) {
	top: 0px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#iconMenu span:nth-child(2) {
	top: 18px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#iconMenu span:nth-child(3) {
	top: 36px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

#iconMenu[data-open="true"] span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: -3px;
	left: 8px;
}

#iconMenu[data-open="true"] span:nth-child(2) {
	width: 0%;
	opacity: 0;
}

#iconMenu[data-open="true"] span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 39px;
	left: 8px;
}
