.wrapper {
	position: relative;
	display: flex;
	width: 90%;
	margin: auto;
	align-items: center;
	z-index: 1;
	padding: 0 5%;
}

.wrapper[data-reverse="true"] {
	flex-direction: row-reverse;
}

.wrapper[data-neg-margin="true"] {
	margin-top: -100px;
	margin-bottom: 140px;
}

.wrapper[data-full-width="true"] {
	width: 100%;
	padding: 0;
}

.wrapper[data-full-width="true"] .image {
	width: 45%;
}

.image {
	width: 55%;
	flex-shrink: 0;
	height: auto;
	max-height: 90vh;
	object-fit: cover;
	aspect-ratio: 3/4;
}

.image[data-small="true"] {
	width: 40%;
}

.image[data-remove-max-height="true"] {
	max-height: unset;
}

.container {
	background-color: var(--white);
	padding: 80px;
	margin: 80px 0;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: flex-start;
	justify-content: center;
	box-shadow: var(--shadow);
}

.heading {
	border-left: 10px solid var(--red);
	font-size: clamp(40px, 2vw, 60px);
	color: var(--red);
	padding-left: 40px;
}

@media screen and (max-width: 1340px) {
	.wrapper {
		padding: 0;
		width: 95%;
	}
}

@media screen and (max-width: 1024px) {
	.wrapper {
		flex-direction: column;
		box-shadow: var(--shadow);
		padding: 0;
	}

	.wrapper[data-reverse="true"] {
		flex-direction: column;
	}

	.image,
	.image[data-small="true"],
	.wrapper[data-full-width="true"] .image {
		width: 100%;
		max-height: 40vh;
		object-fit: cover;
	}

	.container {
		margin: 0;
		box-shadow: unset;
	}
}

@media screen and (max-width: 768px) {
	.container {
		padding: 40px 20px;
	}
}
