.wrapper[data-padding-top="true"] {
	padding-top: 200px;
}

#textContainer {
	position: relative;
	text-align: center;
}

#textContainer[data-align-start="true"] {
	text-align: start;
}

#text {
	display: inline-block;
	transform-origin: 50% 0;
	-webkit-font-smoothing: antialiased;
	transform: translate3d(0, 0, 0);
	color: #f0d8d9;
	letter-spacing: -2px;
	line-height: 30px;
}

@media screen and (max-width: 768px) {
	.wrapper[data-padding-top="true"] {
		padding-top: 100px;
	}

	#textContainer[data-hide-mobile="true"] {
		display: none;
	}
}
