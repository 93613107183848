@font-face {
	font-family: "Indivisible";
	font-weight: normal;
	font-display: swap;
	src: url("/fonts/Indivisible-Regular.woff2") format("woff2");
}
@font-face {
	font-family: "Indivisible";
	font-weight: bold;
	font-display: swap;
	src: url("/fonts/Indivisible-SemiBold.woff2") format("woff2");
}

:root {
	/* COLORS */
	--white: #fff;
	--black: #000;
	--red: #bd081b;

	--light-grey: #f0f0f0;

	--grey: #74716c;
	--dark-grey: #333;

	/* FONTS */
	--heading-font: "Indivisible";
	--body-font: "Indivisible";

	/* TRANSITIONS */
	--short-transition: all 0.3s ease-in-out;
	--long-transition: all 0.5s ease-in-out;
	--xl-transition: all 1s ease-in-out;

	/* SIZING */
	--border-radius: 18px;
	--max-width: 1440px;

	/* SHADOWS */
	--shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
		rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
		rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

/* Reset */
*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	font-family: var(--body-font), sans-serif;
	letter-spacing: 0px;
	scroll-behavior: smooth;
}

html,
body {
	height: 100%;
	background-color: var(--white);
}

body {
	line-height: 1.1;

	-webkit-font-smoothing: antialiased;
}

body a:hover {
	color: var(--red);
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select,
input::placeholder,
textarea::placeholder {
	font: inherit;
	letter-spacing: 0px;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	letter-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h4 > span,
h5 > span,
h6 > span,
h1 > strong,
h2 > strong,
h3 > strong,
h4 > strong,
h4 > strong,
h5 > strong,
h6 > strong {
	font-family: var(--heading-font), sans-serif;
	font-weight: bold;
	letter-spacing: -1px;
}

#root,
#__next {
	isolation: isolate;
}

#__next {
	/* overflow: hidden; */
	min-height: 100vh;
}

/* Text */

a {
	color: var(--black);
	transition: var(--short-transition);
}

a:hover {
	color: var(--green);
}

h1 {
	font-size: 60px;
}

h2 {
	font-size: 50px;
}

h3 {
	font-size: 40px;
}

h4 {
	font-size: 30px;
}

h5 {
	font-size: 20px;
}

p {
	font-size: 18px;
	line-height: 2;
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 48px;
	}
	h2 {
		font-size: 40px;
	}
	h3 {
		font-size: 30px;
	}
	h4 {
		font-size: 24px;
	}
	h5 {
		font-size: 18px;
	}
}

/* Max-width container */

div [data-contain="true"] {
	margin: 0 auto;
	max-width: var(--max-width);
}

/* LISTS */
.wrapper ul {
	margin-top: 20px;
	font-size: 18px;
	padding-inline-start: 60px;
}
.wrapper ul li {
	margin-bottom: 24px;
	list-style: none;
	position: relative;
}

.wrapper ul li::before {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	background-color: var(--red);
	border-radius: 50%;
	top: 4px;
	left: -40px;
}

.wrapper ul li:last-child {
	margin-bottom: 20px;
}

.wrapper ol {
	margin-top: 20px;
	counter-reset: counter;
}

.wrapper ol li {
	list-style: none;
	font-size: 18px;
	margin-bottom: 20px;
	position: relative;
	counter-increment: counter;
	padding-left: 4px;
}

.wrapper ol li::before {
	position: absolute;
	left: -27px;
	top: 2px;
	content: counter(counter);
	color: var(--white);
	font-weight: bold;
	font-size: 16px;
	z-index: 1;
}

.wrapper ol li::after {
	content: "";
	position: absolute;
	background-color: var(--green);
	height: 28px;
	width: 28px;
	left: -36px;
	top: -1px;
	border-radius: 50%;
	z-index: 0;
}

.wrapper ol li:last-child {
	margin-bottom: 20px;
}

/* Input Styles */

form[data-form="true"] {
	container-type: inline-size;
}

.formRow {
	display: flex;
	gap: 20px;
	width: 100%;
	margin-bottom: 30px;
}

.formGroup {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.formGroup label {
	font-size: 14px;
	font-weight: bold;
}

.formGroup input,
.formGroup textarea,
.formGroup select {
	padding: 16px 18px;
	border: none;
	border-bottom: 1px solid var(--black);
}
.formGroup,
.formGroup textarea,
.formGroup select {
	width: 100%;
}

.formGroup > *:focus {
	outline: 1px solid var(--brown);
}

@container (max-width: 400px) {
	form[data-form="true"] .formRow {
		flex-direction: column;
	}
}

/* Carousel */

.carousel-slider {
	box-shadow: var(--shadow);
}

.carousel .thumbs .thumb,
.carousel .thumbs .thumb.selected,
.carousel .thumbs .thumb:hover {
	padding: 0;
	border: none;
	cursor: pointer;
	margin-bottom: 0;
}

.carousel li::before {
	display: none;
}

.carousel .thumbs .thumb > div {
	height: 120px;
	padding: 0;
}

.carousel .thumbs-wrapper .control-arrow {
	background-color: var(--red);
	height: 50px;
	width: 50px;
	border-radius: 50%;
	position: absolute;
}

.carousel .thumbs .thumb > div > img {
	height: 100%;
	object-fit: cover;
}

/* FullCalendar Header Title Override */
@media (max-width: 768px) {
	h2#fc-dom-86 {
		font-size: 1.3rem;
	}
}

/* STUDENT AGREEMENT CSS */
.studentAgreementMain {
	padding: 140px 20px 80px;
}

.studentAgreementForm {
	max-width: 800px;
	margin: 0 auto;
}

.studentAgreementForm h1 {
	margin-bottom: 40px;
}

.studentAgreementCourseSelect {
	width: 50%;
}

.studentAgreementAlertBanner {
	background-color: var(--red);
	border-radius: var(--border-radius);
	padding: 10px 20px;

	max-width: 800px;
	color: var(--white);
	font-weight: bold;
	display: inline-block;
	margin: auto auto 40px;
}

@media screen and (max-width: 1024px) {
	.studentAgreementMain {
		padding: 40px 20px;
	}

	.studentAgreementCourseSelect {
		width: 100%;
	}
}
