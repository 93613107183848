.card {
	background-color: var(--white);
	padding: 16px 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	box-shadow: var(--shadow);
	text-decoration: none;
}

.imageContainer {
	overflow: hidden;
	border-radius: var(--border-radius);
}

.image {
	height: auto;
	max-height: 300px;
	object-fit: cover;
	border-radius: var(--border-radius);
	transition: var(--long-transition);
	width: 100%;
	aspect-ratio: 4/3;
}

.image[data-contain="true"] {
	object-fit: contain;
}

.cardBtnContainer {
	position: relative;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 30px;
	align-items: center;
}

.cardTitle {
	position: relative;
	padding: 16px 0 16px 20px;
}

.cardTitle::before {
	content: "";
	position: absolute;
	background-color: var(--red);
	width: 8px;
	height: 100%;
	left: 0px;
	bottom: 0;
	transition: var(--short-transition);
}

.cardBtnContainer::after {
	content: "";
	position: absolute;
	background-color: var(--red);
	height: 8px;
	width: 8px;
	left: 0;
	bottom: 0;
	transition: var(--short-transition);
}

.cardBtn {
	border-radius: var(--border-radius);
	color: var(--white);
	background-color: var(--red);
	padding: 4px 28px;
	flex-shrink: 0;
}

.card:hover {
	cursor: pointer;
	translate: 0 -8px;
}

.card:hover .image {
	scale: 1.01;
}

.card:hover .cardTitle::before {
	height: 0;
}

.card:hover .cardBtnContainer::after {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.card {
		padding-bottom: 40px;
	}
}
