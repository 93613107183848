.videoContainer {
	position: relative;
	box-shadow: var(--shadow);
	overflow: hidden;
	border-radius: 16px;
	z-index: 1;
}

.youtube {
	height: 700px;
	max-height: 80vh;
}

.youtube iframe {
	width: 100%;
	height: 100%;
}

.video {
	width: 100%;
}

/* iOS hide native media controls */
.video::-webkit-media-controls,
.video .media-controls,
.video::slotted::-webkit-media-controls-container {
	display: none !important;
	visibility: hidden !important;
	opacity: 0 !important;
	-webkit-appearance: none !important;
}

.video[poster] {
	object-fit: cover;
	height: 100%;
	aspect-ratio: 16 / 9;
}

.controlsContainer {
	position: absolute;
	z-index: 2147483647;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.playBtn {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 50%;
	left: 50%;
	background-color: var(--primary-color);
	color: var(--icon-color);
	height: 120px;
	width: 120px;
	border: none;
	border-radius: 50%;
	translate: -50% 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	z-index: 2147483647;
}

.fullscreenBtn {
	position: absolute;
	bottom: 26px;
	right: 10px;
	border: none;
	background-color: transparent;
	color: var(--icon-color);
	cursor: pointer;
	z-index: 2147483647;
}

.volumeControl {
	position: absolute;
	bottom: 26px;
	left: 60px;
	display: flex;
	align-items: center;
	z-index: 2147483647;
}

.volumeBtn {
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: var(--icon-color);
}

.volumeSlider {
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	width: 100%;
	max-width: 100px;
	height: 6px;
	background: var(--secondary-color);
	outline: none;
	border-radius: 20px;
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.volumeControl:hover .volumeSlider {
	opacity: 0.6;
}

.volumeSlider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 12px;
	height: 12px;
	background: var(--primary-color);
	cursor: pointer;
	border-radius: 50%;
}

.volumeSlider::-moz-range-thumb {
	width: 12px;
	height: 12px;
	background: var(--primary-color);
	cursor: pointer;
	border-radius: 50%;
}

.progress {
	position: absolute;
	bottom: 8px;
	left: 0;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 6px;
	background: var(--secondary-color);
	border: 0;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
	z-index: 2147483647;
	cursor: pointer;
}

.progress::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: var(--value);
	height: 100%;
	background: var(--primary-color);
}

.progress::-webkit-slider-thumb {
	margin: 0;
	-webkit-appearance: none;
	appearance: none;
	width: 14px;
	height: 14px;
	background: var(--primary-color);
	cursor: pointer;
	border-radius: 50%;
}

.progress::-moz-range-thumb {
	margin: 0;
	width: 14px;
	height: 14px;
	background: var(--primary-color);
	cursor: pointer;
	border-radius: 50%;
}

.videoContainer[data-active="true"] .playBtn {
	scale: 0.33;
	left: -26px;
	bottom: -20px;
	translate: unset;
}

@media screen and (max-width: 768px) {
	.playBtn {
		width: 60px;
		height: 60px;
	}

	.playBtn > svg {
		width: 20px;
	}

	.videoContainer[data-active="true"] .playBtn {
		scale: 0.5;
		left: -10px;
		bottom: 10px;
	}

	.fullscreenBtn {
		bottom: 30px;
	}

	.volumeControl {
		bottom: 30px;
		left: 40px;
		display: flex;
		align-items: center;
	}

	.volumeSlider {
		display: none;
	}
}
