.map {
	background-color: var(--light-grey);
}

.country {
	fill: var(--grey);
	transition: var(--short-transition);
}

.continent {
	cursor: pointer;
}

.continent[data-active="true"] .country,
.continent:hover .country {
	fill: var(--red);
}
