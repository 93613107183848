.wrapper {
	background-color: var(--white);
}

.featuredImage {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.container {
	display: flex;
	position: relative;
	margin: -150px auto 0;
	max-width: 1024px;
	background-color: var(--white);
	padding: 80px 40px;
	box-shadow: var(--shadow);
}

.heading {
	border-left: 8px solid var(--red);
	padding: 10px 0 10px 20px;
	margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 40px 20px 40px 0;
	}
}
