.form {
	max-width: 900px;
	padding: 60px;
	background-color: var(--white);
}

.form[data-shadow="true"] {
	box-shadow: var(--shadow);
}

.btnContainer {
	display: flex;
	gap: 20px;
}

@media screen and (max-width: 768px) {
	.form {
		padding: 40px;
	}
}
