.wrapper {
	padding: 60px 20px 100px;
}

.container {
	display: flex;

	align-items: flex-start;
	gap: clamp(80px, 10%, 300px);
}

.image {
	min-width: 25%;
	max-width: 40%;
	height: auto;
}

.header {
	border-left: 8px solid var(--red);
	padding: 20px;
	margin-bottom: 40px;
}

.title {
	color: var(--red);
}

@media screen and (max-width: 768px) {
	.container {
		flex-direction: column;
		gap: 40px;
	}

	.image {
		min-width: 80%;
		margin: auto;
	}
}
