.wrapper {
	position: sticky;
	z-index: 1000;
	width: 100%;
	top: 0;
}

.wrapper li::before {
	display: none;
}

.container {
	display: flex;
	padding: 20px;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	left: 50%;
	translate: -50% 0;
	width: 100%;
	transition: var(--short-transition);
}

.wrapper[data-sticky="true"] .container {
	background-color: var(--white);
	box-shadow: var(--shadow);
	padding: 0 0 0 40px;
}

.wrapper[data-sticky="true"] .nav {
	box-shadow: none;
}

.logo {
	min-width: 124px;
}

.menuIcon {
	display: none;
	z-index: 1000;
	position: absolute;
	right: 20px;
	top: 20px;
}

.mobileLogo {
	display: none;
}

.nav {
	position: relative;
	display: flex;
	align-items: center;
	height: 80px;
	background-color: var(--white);
	padding: 0 40px;
	gap: 40px;
	box-shadow: var(--shadow);
}

.nav a,
.nav span {
	font-size: 16px;
}

.nav ul,
.nav li {
	margin: 0 !important;
	padding: 0 !important;
	list-style: none;
	position: unset;
	flex-shrink: 0;
	/* padding-inline-start: 0 !important; */
}

.nav ul li:last-child {
	margin-bottom: 0;
}

.link {
	text-decoration: none;
	display: flex;
	gap: 1px;
	align-items: center;
}

.primary {
	display: flex;
	align-items: center;
	gap: 30px;
	height: 100%;
}

.primary li {
	height: 100%;
	display: flex;
	align-items: center;
	position: unset !important;
}

.primary > li > .link {
	position: relative;
}

.primary > li > .link::after {
	content: "";
	position: absolute;
	width: 0;
	top: 100%;
	height: 2px;
	background-color: var(--red);
	transition: var(--short-transition);
}

.primary > li > .link:hover::after {
	width: 100%;
}

.primary .link {
	transition: var(--short-transition);
}

.primary .link:hover {
	color: var(--red);
}

.primary .secondary {
	position: absolute;
	display: flex;
	align-items: center;
	gap: 20px;
	top: 100%;
	max-height: 0;
	left: 0;
	padding: 0 32px !important;
	min-width: 100%;
	overflow: hidden;
	background-color: var(--white);
	transition: var(--short-transition);
	box-shadow: var(--shadow);
	opacity: 0;
}

.primary > li:hover .secondary {
	padding-bottom: 20px !important;
	padding-top: 20px !important;
	max-height: 100px;
	overflow: visible;
	opacity: 1;
}

.secondary .link {
	color: var(--red);
	padding: 8px;
	display: flex;
	gap: 10px;
	align-items: center;
	position: relative;
	z-index: 1;
}

.secondary .link > * {
	z-index: 1;
}

.secondary .link::after {
	content: "";
	position: absolute;
	background-color: var(--red);
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 0;
	transition: var(--short-transition);
	transform-origin: bottom;
	transform: scale(1, 0);
}

.secondary .link:hover {
	color: var(--white);
}

.secondary .link:hover::after {
	height: 100%;
	transform: scale(1);
}

.ctaContainer {
	display: flex;
	gap: 10px;
}

@media screen and (max-width: 1440px) {
	.wrapper[data-sticky="true"] .container {
		padding: 0 0 0 20px;
	}

	.nav {
		padding: 0 20px;
		gap: 20px;
	}

	.primary {
		gap: 20px;
	}
}

@media screen and (max-width: 1024px) {
	.wrapper {
		position: relative;
	}
	.menuIcon {
		display: block;
	}

	.container {
		position: relative;
		top: unset;
		left: unset;
		translate: unset;
	}

	.nav {
		position: fixed;
		height: 100vh;
		width: 100%;
		max-width: 600px;
		z-index: 999;
		right: -130%;
		top: 0;
		overflow: scroll;
		flex-direction: column;
		padding: 60px 40px;
		box-shadow: rgba(0, 0, 0, 0.65) -25px 0px 100px 10px;
		transition: var(--short-transition);
	}

	.nav[data-open="true"] {
		right: 0;
	}

	.primary {
		flex-direction: column;
		height: auto;
		gap: 16px;
	}

	.primary li {
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.primary .secondary {
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		gap: 2px;
		top: 0;
		left: unset;
		background-color: var(--white);
		max-height: fit-content;
		overflow: visible;
		padding: 8px 16px 0;
		opacity: 1;
		box-shadow: none;
	}

	.mobileLogo {
		display: block;
	}

	.ctaContainer {
		flex-direction: column;
	}
}
