.wrapper {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 95vh;
  width: 100%;
}

.container {
  position: relative;
  z-index: 1;
  padding: 200px 20px;
}

@media (max-width: 768px) {
	.container {
		padding: 20px 20px;
	}

}