.cardWrapper {
	text-decoration: none;
}

.card {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: var(--border-radius);
	position: relative;
	overflow: hidden;
	color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: var(--shadow);

	padding: 30px;
	aspect-ratio: 1/1;
}

.card::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 0;
}

.card[data-variant="Video"] {
	border-radius: 0;
}

.card[data-variant="Video"],
.card[data-variant="Large"] {
	aspect-ratio: 16 / 9;
	padding: 60px 33% 60px 60px;
	min-height: 100%;
}

.card > * {
	position: relative;
	z-index: 1;
}

.title {
	font-size: 32px;
}

.btn {
	display: flex;
	gap: 8px;
	font-weight: bold;
	align-items: center;
	font-size: 18px;
}

.card[data-variant="Video"] .btn,
.card[data-variant="Large"] .btn {
	padding: 12px 40px;
	border-radius: var(--border-radius);
	background-color: var(--white);
	color: var(--red);
	font-weight: bold;
	font-size: 16px;
}

.iconWrapper {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: var(--red);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 60px;
	right: 60px;
}

@media screen and (max-width: 768px) {
	.title {
		font-size: 26px;
	}
	.card[data-variant="Video"],
	.card[data-variant="Large"] {
		aspect-ratio: 16 / 12;
		padding: 20px;
	}

	.iconWrapper {
		right: 20px;
		bottom: 20px;
	}
}
