.container {
	display: flex;
	gap: 20px;
	padding: 20px 60px;
	flex-direction: column;
	align-items: flex-start;
}

@media (max-width: 768px) {
	.container {
		padding: 0px;
	}

	.productCard {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-bottom: 1px solid var(--grey);
	padding: 20px 0;
}

.closeIcon {
	position: absolute;
	top: 20px;
	left: 20px;
}

.form {
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 40px;
}

.productCard {
	display: flex;
	justify-content: space-between;
	gap: 40px;
	width: 100%;
}

.quantity {
	display: flex;
	align-items: center;
}

.quantityPlus,
.quantityMinus {
	background-color: var(--light-grey);
	color: var(--dark-grey);
	border: none;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.quantityInput {
	border: none;
	/* width: 40px; */
	text-align: center;
	/* font-size: 24px; */
}

.btnContainer {
	display: flex;
	gap: 20px;
}
