.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	box-shadow: var(--shadow);
	text-decoration: none;
	text-align: center;
	gap: 30px;
	border-radius: var(--border-radius);
	padding-bottom: 30px;
}

.wrapper:hover {
	scale: 0.99;
}

.container {
	padding: 20px 40px 20px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.image {
	object-fit: contain;
	aspect-ratio: 4/3;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.learn {
	background-color: var(--red);
	padding: 10px 30px;
	color: var(--white);
	font-weight: bold;
	transition: var(--short-transition);
	border: 3px solid var(--red);
	border-radius: 30px;
	letter-spacing: 0px;
}

.wrapper:hover .learn {
	background-color: var(--white);
	color: var(--red);
}
