.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	cursor: pointer;
	border: none;
	background-color: transparent;
	text-decoration: none;
}

.circle {
	border-radius: 50%;
	width: 100%;
	overflow: hidden;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	aspect-ratio: 1/1;
	transition: var(--short-transition);
	outline: 6px solid var(--white);
	outline-offset: -6px;
	margin-bottom: 20px;
}

.wrapper:hover .circle {
	outline-color: var(--red);
	outline-offset: 8px;
}

.title {
	color: var(--red);
}
