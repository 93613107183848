.wrapper {
	background-color: var(--light-grey);
	container-type: inline-size;
}

.container {
	padding: 60px 0 30px;
	font-weight: bold;
	font-size: 22px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.container a {
	text-decoration: none;
}

.container a:hover {
	color: var(--red);
}

.container span {
	color: var(--red);
}

.arrowBack {
	display: none;
}

@container (max-width: 768px) {
	.container {
		gap: 10px;
		padding: 40px 20px 0;
	}

	.container > * {
		display: none;
	}

	.container > *:nth-last-child(3),
	.container > *:nth-last-child(4) {
		display: block;
	}
}
