.wrapper {
	background-color: var(--black);
	color: var(--white);
	padding: 100px 10%;
}

.container a {
	color: var(--white);
	text-decoration: none;
}

.container a:hover {
	color: var(--red);
}

.contentContainer {
	display: flex;
	gap: 60px 10%;
	padding-bottom: 80px;
}

.logoContainer {
	flex-shrink: 0;
}

.socialLinks {
	display: flex;
	gap: 20px;
	margin: 30px 0;
}

.colContainer {
	display: grid;
	gap: 60px 5%;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
}

.col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.colHeading {
	font-size: 20px;
	font-weight: bold;
	min-height: 35px;
}

.footerItem {
	display: flex;
	gap: 10px;
	align-items: center;
	font-size: 18px;
}

.col a {
	line-height: 1.75;
}

.col a:hover {
	margin-left: 2px;
}

.addressTitle {
	color: var(--red);
	font-weight: bold;
}

.copyrightContainer {
	border-top: 1px solid var(--grey);
	padding: 30px 0 0;
	display: flex;
	justify-content: space-between;
}

.copyrightContainer > * {
	font-size: 14px;
}

.termsContainer {
	display: flex;
	gap: 30px;
}

@media screen and (max-width: 1024px) {
	.wrapper {
		padding: 80px 20px;
	}

	.contentContainer {
		flex-direction: column;
	}
}

@media screen and (max-width: 768px) {
	.logoContainer,
	.logo img {
		margin: auto;
		text-align: center;
	}

	.colContainer {
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 0;
	}

	.col {
		align-items: center;
	}

	.col[data-variation="default"] {
		margin-bottom: 40px;
	}

	.colHeading {
		min-height: unset;
	}

	.address {
		text-align: center;
	}

	.copyrightContainer {
		flex-direction: column-reverse;
		gap: 40px;
		text-align: center;
	}

	.termsContainer {
		flex-direction: column;
	}
}
